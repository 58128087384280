import React from "react";

import styles from "./alert.module.scss";

type Props = {
    message: string;
}

export const Alert = (props: Props) => {
    if (props.message.length < 1) {
        return null;
    }

    const messagePieces = props.message.split(':');

    return (
        <div className={`${styles.alert} ${styles[messagePieces[0]]}`}>
            {messagePieces[1]}
        </div>
    )
};

export default Alert;

import { useState } from "react";
import {Link} from "react-router-dom";

import { signIn, changePassword } from "../../services/auth";

import Alert from "../../components/alert";

import styles from "./login.module.scss";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [session, setSession] = useState("");
    const [authState, setAthState] = useState("login"); //login|changePassword
    const [message, setMessage] = useState('');

    const handleSignIn = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        try {
            const signInSession = await signIn(email, password);
            if (signInSession?.AuthSession && typeof signInSession.AuthSession.AccessToken !== "undefined") {
                sessionStorage.setItem("accessToken", signInSession.AuthSession.AccessToken);
                if (sessionStorage.getItem("accessToken")) {
                    window.location.href = "/";
                } else {
                    setMessage(`error:Token was not set properly`);
                }
            } else if (signInSession?.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                setPassword("");
                setSession(signInSession.Session || "");
                setAthState("changePassword");
            } else {
                setMessage(`error:Token is undefined`);
            }
        } catch (error) {
            if (error instanceof Error) {
                setMessage(`error:${error.message}`);
            }
        }
    };

    const handlePasswordChange = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage(`error:Passwords do not match`);
            return;
        }

        try {
            await changePassword(email, password, session);
            setMessage(`info:Passwords changes successfully`);
            setAthState("login");
        } catch (error) {
            if (error instanceof Error) {
                setMessage(`error:${error.message}`);
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <Alert message={message} />

                <div className={styles.inner}>
                    <h4>{authState === 'changePassword' ? "Change your password" : "Sign in"}</h4>
                    {
                        authState === 'changePassword' && (
                            <div className={styles.note}>
                                <small>You need to change or set a new password before you can sign in.</small>
                            </div>
                        )
                    }
                    <form onSubmit={authState === 'changePassword' ? handlePasswordChange : handleSignIn}>
                        {
                            authState === 'login' && (
                                <div className={styles.inputContainer}>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            )
                        }
                        <div className={styles.inputContainer}>
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {
                            authState === 'changePassword' && (
                                <div className={styles.inputContainer}>
                                    <label htmlFor="newPassword">Confirm password</label>
                                    <input
                                        id="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>
                            )
                        }
                        <button type="submit">{authState === 'changePassword' ? "Set password" : "Sign in"}</button>
                        <Link to="/">cancel</Link>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;

import React from "react";
import {Link} from "react-router-dom";

import styles from "../../pages/home/home.module.scss";

export const Identify = () => {
    return (
        <div className={`${styles.sliderMain} ${styles.feature}`}>
            <div>
                <div>
                    <Link to="/identify">
                        <div className={styles.titleWrapper}>
                            <div className={styles.sweetTitle}>
                                <span data-text="Identify">Identify</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div>
                    <p>
                        A preview of the system that will parse an image, identify the dominant colours and provide
                        options for the user to replace them with a colour of their choice.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Identify;

import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import Home from "pages/home";
import ColourMatcher from "pages/colour-matcher";
import Identify from "./pages/identify";
import LoginPage from "./pages/login";
import Error404 from "./pages/error/404";

export default function App() {
    const isAuthenticated = () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return !!accessToken;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<Home/>}/>
                    <Route path="/login"
                        element={ isAuthenticated() ? <Navigate replace to="/" /> : <LoginPage /> }/>
                    <Route path="/colour-matcher" element={<ColourMatcher/>}/>
                    <Route path="/identify"
                           element={ isAuthenticated() ? <Identify /> : <Navigate replace to="/login" /> }/>
                    <Route path="*" element={<Error404/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('app')!);
root.render(<App/>);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('SW registered: ', registration);
            }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
